<template>
  <div class="filler fill-height" v-if="authorizedAccess">
    <Navbar />
    <v-main class="">
      <router-view />
    </v-main>

    <Footer />
  </div>
  <div class="fill-height" v-else>
    <v-main>
      <UnauthorizedAccess :user="user" />
    </v-main>
  </div>
</template>

<script>
// @ is an alias to /src
import Navbar from "@/components/layout/adminNavbar";
import Footer from "@/components/layout/footer";
import jwtdecode from "jwt-decode";
import UnauthorizedAccess from "@/components/mijini/UnauthorizedAccess";

export default {
  name: "Home",
  components: {
    Navbar,
    Footer,
    UnauthorizedAccess,
  },
  data() {
    return {
      user: "",
      authorizedAccess: false,
    };
  },

  created() {
    this.getUser();
  },

  methods: {
    getUser() {
      this.user = jwtdecode(
        this.$store.state.token,
        this.$store.state.accessKey
      );

      if (this.user.accessLevel.isSuperAdmin) this.authorizedAccess = true;
      else this.authorizedAccess = false;
    },
  },
};
</script>
<style scoped>
.filler {
  background-color: #eff1f5;
}
</style>
