<template>
  <nav>
    <v-navigation-drawer
      mini-variant
      expand-on-hover
      v-model="drawer"
      dark
      app
      class="gradients"
    >
      <v-list dense>
        <div v-if="checkConnection()">
          <v-progress-linear
            v-if="user == null"
            indeterminate
            color="primary darken-3"
            class="my-4 py-4 grey--text"
          >
            {{ $t("loading...") }}</v-progress-linear
          >
        </div>
        <div v-else>
          <v-progress-linear
            color="red darken-5"
            class="my-2 mx-3 rounded py-2 black--text caption"
          >
            {{ $t("system is offline") }}</v-progress-linear
          >
        </div>
        <v-divider class="mb-1"></v-divider>

        <v-list-item
          :color="$store.state.secondaryColor"
          v-for="link in links"
          :key="link.text"
          class="py-0"
          @click="goTo(link)"
        >
          <v-list-item-icon>
            <v-icon small> {{ link.icon }}</v-icon>
          </v-list-item-icon>
          <v-list-item-title
            class="caption white--text darken-1 font-weight-light text-capitalize"
          >
            {{ link.text }}
          </v-list-item-title>
        </v-list-item>
      </v-list>

      <template v-slot:append>
        <v-list-item>
          <v-menu
            open-on-hover
            v-model="menu"
            :close-on-content-click="false"
            :nudge-width="200"
            offset-x
          >
            <template v-slot:activator="{ on, attrs }">
              <v-list-item-icon v-bind="attrs" v-on="on">
                <v-icon>mdi-account-circle-outline</v-icon></v-list-item-icon
              >
            </template>

            <v-card>
              <v-list>
                <v-list-item>
                  <v-list-item-avatar>
                    <img :src="user.avatar" alt="profile" />
                  </v-list-item-avatar>

                  <v-list-item-content>
                    <v-list-item-title class="subtitle-2">{{
                      user.names.first +
                      " " +
                      user.names.middle +
                      " " +
                      user.names.last
                    }}</v-list-item-title>
                    <v-list-item-subtitle class="caption">{{
                      ownerType
                    }}</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-list>

              <v-divider></v-divider>

              <v-list>
                <div v-for="menu in userMenu" :key="menu.icon">
                  <v-list-item @click="goTo(menu)">
                    <v-list-item-action>
                      <v-icon left dense>{{ menu.icon }}</v-icon>
                    </v-list-item-action>
                    <v-list-item-title class="blue-grey--text subtitle-2">{{
                      menu.text
                    }}</v-list-item-title>
                  </v-list-item>
                </div>
                <v-divider></v-divider>
                <v-list-item @click="logout">
                  <v-list-item-action
                    ><v-icon left dense>mdi-exit-to-app</v-icon>
                  </v-list-item-action>
                  <v-list-item-title class="blue-grey--text subtitle-2">{{
                    $t("sign out")
                  }}</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-card>
          </v-menu>
        </v-list-item>
      </template>
    </v-navigation-drawer>

    <!-- TOP BAR -->

    <v-app-bar app dense class="elevation-1 white">
      <v-app-bar-nav-icon @click="drawer = !drawer"> </v-app-bar-nav-icon>

      <v-card @click="reload()" class="transparent pa-2" flat>
        <v-img
          contain
          src="@/assets/Tlogo.png"
          width="120"
          height="auto"
        ></v-img>
      </v-card>

      <v-toolbar-title
        class="blue-grey--text text-uppercase font-weight-light title darken-2"
        >{{ $store.state.windowTitle }}
      </v-toolbar-title>

      <v-spacer></v-spacer>

      <div v-if="checkConnection()">
        <span v-if="user == null">{{ $t("loading...") }}</span>
      </div>

      <div v-else>
        <span class="font-weight-black red--text">{{
          $t("system is offline")
        }}</span>
      </div>
      <v-btn small :color="$store.state.secondaryColor" @click="wizard()">
        <v-icon small left>add</v-icon>
        <span class="caption">{{ $t("create business owner") }}</span>
      </v-btn>

      <v-menu offset-y v-model="menuLang" class="pl-1">
        <template v-slot:activator="{ on }">
          <v-btn text v-on="on" style="cursor: pointer">
            <flag :iso="$i18n.locale == 'en' ? 'us' : $i18n.locale" />
            <span class="ml-2 subtitle-2">{{
              $i18n.locale.toUpperCase()
            }}</span>
          </v-btn>
        </template>
        <v-list dense max-height="300" class="overflow-y-auto pa-0" tile>
          <div v-for="item in languages" :key="item.lang">
            <v-list-item @click="switchLanguage(item.lang)" class="">
              <v-list-item-icon>
                <flag :iso="item.flag" />
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title class="caption">
                  {{ item.lang.toUpperCase() }}
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-divider></v-divider>
          </div>
        </v-list>
      </v-menu>

      <v-menu
        v-model="notificationsMenu"
        :close-on-content-click="false"
        :nudge-width="150"
        offset-x
        max-height="350"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-badge
            :content="getNumberOfNotifications(notifications)"
            :value="getNumberOfNotifications(notifications)"
            :color="$store.state.primaryColor"
            overlap
            class="ma-3"
          >
            <v-icon v-bind="attrs" v-on="on">mdi-bell-ring-outline</v-icon>
          </v-badge>
        </template>

        <v-card>
          <v-list>
            <v-list-item class="grey lighten-2">
              <v-list-item-content>
                <v-list-item-title>
                  <span class="text-h5">{{
                    $t("notifications")
                  }}</span></v-list-item-title
                >
              </v-list-item-content>
            </v-list-item>
            <!-- ############## -->
            <v-list-item v-for="item in notifications" :key="item.id">
              <v-list-item-content>
                <v-list-item-title class="body-2 font-weight-black">{{
                  item.message
                }}</v-list-item-title>
                <v-list-item-subtitle
                  class="caption font-italic font-weight-regular"
                  >{{
                    formatDate(item.dateTime) +
                    "(" +
                    formatDateMinuteTime(item.dateTime) +
                    ")"
                  }}</v-list-item-subtitle
                >
              </v-list-item-content>

              <v-btn icon @click="confirmDelete(item.id)">
                <v-icon color="red">mdi-delete</v-icon>
              </v-btn>
            </v-list-item>
          </v-list>
          <v-divider></v-divider>
          <v-card-actions>
            <v-spacer></v-spacer>

            <v-btn color="primary" text @click="notificationsMenu = false">
              {{ $t("close") }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-menu>
    </v-app-bar>

    <v-dialog v-model="dialogDelete" persistent max-width="290">
      <v-card>
        <v-card-title class="text-h5"
          >{{ $t("delete notification") }}
        </v-card-title>
        <v-card-text class="pa-4">
          {{
            $t(
              "are you sure you want to delete this notification from the system"
            )
          }}?
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="green darken-1" text @click="dialogDelete = false">
            {{ $t("cancel") }}
          </v-btn>
          <v-btn
            color="red darken-1"
            text
            :loading="loadingDelete"
            @click="deleteNotification()"
          >
            {{ $t("ok") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-snackbar
      v-model="snackSuccess"
      bottom
      right
      color="success lighten-5 success--text text--darken-3"
      :timeout="4000"
    >
      <span>{{ $t("notification deleted") }}</span>
      <template v-slot:action="{ attrs }">
        <v-btn
          v-bind="attrs"
          icon
          color="success darken-3"
          @click="snackSuccess = false"
        >
          <v-icon>close</v-icon>
        </v-btn>
      </template>
    </v-snackbar>

    <v-snackbar
      v-model="snackError"
      bottom
      right
      color="red lighten-5 error--text text--darken-3"
      :timeout="4000"
    >
      <span>{{ $t("could not delete notification. Try again later") }}</span>
      <template v-slot:action="{ attrs }">
        <v-btn
          v-bind="attrs"
          icon
          color="error darken-3"
          @click="snackError = false"
        >
          <v-icon>close</v-icon>
        </v-btn>
      </template>
    </v-snackbar>
  </nav>
</template>

<script>
import jwtdecode from "jwt-decode";
import db from "@/plugins/fb";

export default {
  data: () => ({
    drawer: true,
    menuLang: false,
    ownerType: "",
    menu: false,
    languages: [
      {
        flag: "us",
        lang: "en",
      },
      {
        flag: "fr",
        lang: "fr",
      },
    ],
    snackError: false,
    snackSuccess: false,
    dialogDelete: false,
    loadingDelete: false,
    notificationsMenu: false,
    notificationId: "",

    notifications: [],
    //check if user is logged in

    isLoggedIn: false,
    currentUser: false,
    user: "",
    internetConnection: "",
  }),

  created() {
    this.getUser();
    window.addEventListener("online", this.checkConnection);
    window.addEventListener("offline", this.checkConnection);
  },
  computed: {
    links() {
      return [
        // changed daashboard link
        { icon: "dashboard", text: this.$t("dashboard"), route: "Dashboard" },
        {
          icon: "fas fa-cog",
          text: this.$t("configurations"),
          route: "Configurations",
        },
        {
          icon: "fas fa-user-check",
          text: this.$t("clients"),
          route: "ViewOwner",
        },
        {
          icon: "fas fa-users",
          text: this.$t("system users"),
          route: "View",
        },
        { icon: "fas fa-key", text: this.$t("licenses"), route: "Packages" },
        { icon: "fas fa-file-pdf", text: this.$t("reports"), route: "Reports" },
        { icon: "fas fa-history", text: this.$t("logs"), route: "Logs" },
        // { icon:"dashboard",       text:'suppliers',       route:'suppliers'     },
      ];
    },
    userMenu() {
      return [
        // changed daashboard link
        { icon: "fas fa-user", text: this.$t("profile"), route: "ProfileView" },
      ];
    },
  },

  methods: {
    checkConnection() {
      this.internetConnection = navigator.onLine ? true : false;
      return this.internetConnection;
    },

    getUser() {
      this.user = jwtdecode(
        this.$store.state.token,
        this.$store.state.accessKey
      );

      var userAccess = this.user.accessLevel.isSuperAdmin
        ? 0
        : this.user.accessLevel.isOwner
        ? 1
        : this.user.accessLevel.isManager
        ? 2
        : this.user.accessLevel.isWaiter
        ? 3
        : this.user.accessLevel.isChef
        ? 4
        : this.user.accessLevel.isCashier
        ? 5
        : 6;

      if (userAccess == 0) {
        this.ownerType = "SuperAdmin";
      } else if (userAccess == 1) {
        this.ownerType = "Owner";
      } else if (userAccess == 2) {
        this.ownerType = "Manager";
      } else if (userAccess == 3) {
        this.ownerType = "Waiter";
      } else if (userAccess == 4) {
        this.ownerType = "Chef";
      } else if (userAccess == 5) {
        this.ownerType = "Cashier";
      }
    },

    goTo(data) {
      this.$store.commit("SET_WINDOWTITLE", data.text);
      this.$router.push({ name: data.route });
    },

    reload() {
      this.$store.commit("SET_WINDOWTITLE", "Dashboard");
      this.$router.push({ name: "Dashboard" });
    },

    // logout user

    logout: function () {
      let cleardata = {
        status: false,
        userId: "",
      };

      var data = jwtdecode(
        this.$store.state.token,
        this.$store.state.accessKey
      ); //Fetching current user details

      var currentLog = this.$store.state.logger; // Fetching Existing Logs  on store

      // Updating Log out Date and Time
      if (currentLog != "") {
        db.collection("logs").doc(currentLog).update({
          dateTimeOut: new Date(),
        });
      }

      // Update user session

      if (data.accessLevel.id != "") {
        db.collection("users").doc(data.accessLevel.id).update({
          status: false,
        });
      }

      //Clear Vuex Store data

      let clearLogs = "";
      let clearToken = "";
      this.$store.commit("SET_LOGS", clearLogs);
      this.$store.commit("SET_SESSION", cleardata);
      this.$store.commit("SET_TOKEN", clearToken);

      //Redirect to Login page and Clear Local storage data cache and

      localStorage.clear();
      var lang = "en";
      this.$i18n.locale = lang;

      //store translation language to store
      localStorage.setItem("lang", lang);
      this.$router.push("/");
    },

    getNotifications() {
      this.notifications = [];
      db.collection("notifications")
        .where("ownerId", "==", this.$store.state.currentUser)
        .get()
        .then((querySnapshot) => {
          querySnapshot.forEach((doc) => {
            this.notifications.push({
              id: doc.id,
              ...doc.data(),
            });
            this.notifications.sort(function (a, b) {
              return b.dateTime.seconds - a.dateTime.seconds;
            });
          });
          this.loading = false;
        });
    },
    //return number of notifications
    getNumberOfNotifications(item) {
      var size = item.length;

      return size;
    },
    switchLanguage(lang) {
      this.$i18n.locale = lang;
      //store translation language to store
      localStorage.setItem("lang", lang);
      this.$router.push({ params: { lang: lang } });
      // setTimeout(() => window.location.reload(), 500);
    },
    confirmDelete(id) {
      this.notificationId = id;
      this.dialogDelete = true;
    },
    deleteNotification() {
      this.loadingDelete = true;
      db.collection("notifications")
        .doc(this.notificationId)
        .delete()
        .then(() => {
          this.snackSuccess = true;
          this.dialogDelete = false;

          this.loadingDelete = false;
          this.getNotifications();
        })
        .catch(() => {
          this.snackError = true;
          this.dialogDelete = false;

          this.loadingDelete = false;
        });
    },
  },
};
</script>
